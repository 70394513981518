.hero-section {
  background-color: #f5f5f5;
  padding: 40px 0;
  text-align: center;
}

.about-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.service-box {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.service-box:hover {
  transform: translateY(-5px);
}
